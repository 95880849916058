/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */

import { Button } from '@pancakeswap/uikit'
import ExternalLink from '@pancakeswap/uikit/src/components/ExternalLink/ExternalLink'
import { formatNumber } from '@pancakeswap/utils/formatBalance'

import { useRouter } from 'next/router'
import { isDesktop } from 'react-device-detect'
import { useStatsHome } from 'state/info/getStatsHome'
import styled from 'styled-components'

const socialList = [
  {
    link: 'https://twitter.com/mummyftm',
    img: '/images/footer/twitter.svg',
    imgFooter: '/images/home/landing/ic-twitter.svg',
    name: 'Twitter',
    boxColor: '#1D2B37',
  },
  {
    link: 'https://medium.com/@mummyftm',
    img: '/images/footer/medium.svg',
    imgFooter: '/images/home/landing/ic-medium.svg',
    name: 'Medium',
    boxColor: '#1D2637',
  },
  {
    link: 'https://t.me/mummyftm',
    img: '/images/footer/telegram.svg',
    imgFooter: '/images/home/landing/ic-telegram.svg',
    name: 'Telegram',
    boxColor: '#1D3337',
  },
  {
    link: 'https://discord.gg/xqSJaw8sUB',
    img: '/images/footer/discord.svg',
    imgFooter: '/images/home/landing/ic-discord.svg',
    name: 'Discord',
    boxColor: '#201D37',
  },
  {
    link: 'https://linktr.ee/mummyfiftm',
    img: '/images/footer/linktree.svg',
    imgFooter: '/images/home/landing/ic-linktree.svg',
    name: 'Linktree',
    boxColor: '#271843',
  },
]

const partnerList = [
  {
    img: 'images/home/landing/fantom.svg',
    link: '/#',
  },
  {
    img: 'images/home/landing/fantom.svg',
    link: '/#',
  },
  {
    img: 'images/home/landing/fantom.svg',
    link: '/#',
  },
  {
    img: 'images/home/landing/fantom.svg',
    link: '/#',
  },
  {
    img: 'images/home/landing/fantom.svg',
    link: '/#',
  },
  {
    img: 'images/home/landing/fantom.svg',
    link: '/#',
  },
]

const featureList = [
  {
    label: 'Swap Tokens',
    desc: 'Trade tokens seamlessly and permissionless on Fantom.',
    icon: 'images/home/landing/ic-swap.svg',
    link: '/swap',
    external: false,
    disabled: false,
  },
  {
    label: 'Supply Liquidity',
    desc: 'Provide concentrated liquidity to earn swap fees and token emissions.',
    icon: 'images/home/landing/ic-liquidity.svg',
    link: '/liquidity',
    external: false,
    disabled: false,
  },
  {
    label: 'Incentives',
    desc: 'Earn passive income with your beloved LPs.',
    icon: 'images/home/landing/ic-farm.svg',
    link: 'https://incentives.skeleton.finance/',
    external: false,
    disabled: false,
  },
  {
    label: 'Analytics',
    desc: "In-depth analysis of Skeleton's performance and trading activities.",
    icon: 'images/home/landing/ic-analytics.svg',
    link: '/info',
    external: false,
    disabled: false,
  },

  {
    label: 'NFT',
    desc: 'Become a Skeleton NFT holder, enjoy our earning features, and become a vital part of our community.',
    icon: 'images/home/landing/ic-nft.svg',
    link: 'https://doc.skeleton.finance/products/nft',
    external: true,
    disabled: false,
  },
  {
    label: 'Governance',
    desc: 'Shape the future of Skeleton with your voting power.',
    icon: 'images/home/landing/ic-governance.svg',
    link: 'https://doc.skeleton.finance/',
    external: false,
    disabled: true,
  },
]

const Home: React.FC<React.PropsWithChildren> = () => {
  const router = useRouter()
  const handleClick = (link) => {
    router.push(link)
  }

  const stats = useStatsHome()

  return (
    <Container
      style={{
        background: isDesktop ? `url('/images/home/landing/bg.png')` : `url('/images/home/landing/bg-ipad.png')`,
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <Wrapper>
        <LeftCotent>
          <Title>
            SKELETON <span>FINANCE</span>{' '}
          </Title>
          <Desc>Swap, earn, and much more with the premier trading and liquidity marketplace on Fantom</Desc>

          <Socials>
            {socialList.map((item) => (
              <ExternalLink href={item.link} key={item.name}>
                <img src={item.img} alt={item.name} />
              </ExternalLink>
            ))}
          </Socials>
          <ButtonActions>
            <Button onClick={() => handleClick('/swap')} className="btn-launch">
              Launch App
            </Button>
            <a href="https://doc.skeleton.finance/" target="_blank" className="btn-docs" rel="noreferrer">
              <button>
                View Docs{' '}
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.56247 10.4951C4.30212 10.7554 4.30212 11.1776 4.56247 11.4379C4.82282 11.6983 5.24493 11.6982 5.50528 11.4379L10.9669 5.97631L10.9669 10.0237C10.9669 10.3919 11.2654 10.6904 11.6335 10.6904C12.0017 10.6904 12.3002 10.3919 12.3002 10.0237L12.3002 4.36683C12.3002 4.19002 12.23 4.02045 12.1049 3.89543C11.9799 3.7704 11.8104 3.70017 11.6335 3.70017L5.97669 3.70017C5.6085 3.70017 5.31002 3.99864 5.31002 4.36683C5.31002 4.73502 5.6085 5.0335 5.97669 5.0335L10.0241 5.0335L4.56247 10.4951Z"
                    fill="white"
                  />
                </svg>
              </button>
            </a>
          </ButtonActions>
        </LeftCotent>
        <RightContent>
          <img alt="trade" src="images/home/landing/trade.png" />
        </RightContent>
      </Wrapper>
      <Stats>
        <div className="container">
          <div>
            <div className="stats-amount">
              ${stats?.data?.totalVolumeTraded ? formatNumber(+stats?.data?.totalVolumeTraded?.toFixed(0), 0) : '--'}
            </div>
            <div className="stats-label">Total Volume Traded</div>
          </div>
          <div>
            <div className="stats-amount">
              {stats?.data?.allTimeTrades ? formatNumber(stats?.data?.allTimeTrades, 0) : '--'}
            </div>
            <div className="stats-label">All-time Trades</div>
          </div>
          <div>
            <div className="stats-amount">
              {' '}
              ${stats?.data?.totalValueLocked ? formatNumber(+stats?.data?.totalValueLocked?.toFixed(0), 0) : '--'}
            </div>
            <div className="stats-label">Total Value Locked</div>
          </div>
          <div>
            <div className="stats-amount">
              {stats?.data?.totalUsers ? formatNumber(stats?.data?.totalUsers, 0) : '--'}
            </div>
            <div className="stats-label">Total Users</div>
          </div>
        </div>
      </Stats>
      <TradeBox>
        <TradeBoxContent className="container">
          <div className="left-content">
            <img src="images/home/landing/trade-img.svg" alt="trade" />
          </div>
          <div className="right-content">
            <div className="tradebox-title">
              <div>Trade anything.</div>
              <div>No registration, no hassle.</div>
            </div>
            <div className="tradebox-desc">Lightning-fast token swapping with near-zero gas fees.</div>

            <div className="tradebox-actions">
              <button className="btn-launch" onClick={() => handleClick('/swap')}>
                Swap now
              </button>
              <a className="btn-docs" href="https://doc.skeleton.finance/" target="_blank" rel="noreferrer">
                <button>
                  View Docs{' '}
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.56247 10.4951C4.30212 10.7554 4.30212 11.1776 4.56247 11.4379C4.82282 11.6982 5.24493 11.6982 5.50528 11.4379L10.9669 5.97631L10.9669 10.0237C10.9669 10.3919 11.2654 10.6904 11.6335 10.6904C12.0017 10.6904 12.3002 10.3919 12.3002 10.0237L12.3002 4.36683C12.3002 4.19002 12.23 4.02045 12.1049 3.89543C11.9799 3.7704 11.8104 3.70017 11.6335 3.70017L5.97669 3.70017C5.6085 3.70017 5.31002 3.99864 5.31002 4.36683C5.31002 4.73502 5.6085 5.0335 5.97669 5.0335L10.0241 5.0335L4.56247 10.4951Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </a>
            </div>
          </div>
        </TradeBoxContent>
        <TradeBoxImg1 style={{ backgroundImage: `url('/images/home/landing/trade-bg.png')` }}> </TradeBoxImg1>
      </TradeBox>
      <Features>
        <FeatureTitle>Features</FeatureTitle>
        <div className="container">
          {featureList.map((item) => {
            return (
              <ItemFeature key={item.label}>
                <img src={item.icon} alt="icon" />
                <div className="feature-item-box">
                  {item.external ? (
                    <a className="feature-title" href={item.link} target="_blank" rel="noreferrer">
                      {' '}
                      {item.label} <img src="images/home/landing/ic-link.svg" alt="link" />{' '}
                    </a>
                  ) : (
                    <div
                      className="feature-title"
                      style={{ cursor: item.disabled ? 'not-allowed' : '' }}
                      onClick={() => (item.disabled ? '' : handleClick(item.link))}
                    >
                      {item.label} <img src="images/home/landing/ic-link.svg" alt="link" />{' '}
                    </div>
                  )}

                  <div className="feature-desc">{item.desc}</div>
                </div>
              </ItemFeature>
            )
          })}
        </div>
      </Features>
      {/* <Partner>
        <PartnerTitle>Partners</PartnerTitle>
        <PartnerContent className="container">
          {partnerList.map((item, idx) => (
            <a href={item.link} target="_blank" rel="noreferrer">
              <img key={idx} src={item.img} alt="partner" />
            </a>
          ))}
        </PartnerContent>
      </Partner> */}
      <Community style={{ backgroundImage: `url('/images/home/landing/footer-bg.png')` }}>
        <div className="container">
          <FooterLeft>
            <a href="https://www.mummy.finance/#/" target="_self">
              <img alt="mmy-logo" src="/images/home/landing/mmy-logo.svg" />
            </a>
            <FooterWrapper1>
              <div className="bottom">
                Copyright © {new Date().getFullYear()} Skeleton Finance. All rights reserved.
              </div>
            </FooterWrapper1>
          </FooterLeft>
          <FooterRight>
            <CommunityTitle>Join our ever-growing Community</CommunityTitle>
            <SocialList>
              {socialList.map((item) => (
                <SocialBox style={{ background: item.boxColor }} href={item.link} target="_blank">
                  <img className="icon-img" src={item.imgFooter} alt={item.name} />
                  {/* <div>{item.name}</div> */}
                </SocialBox>
              ))}
            </SocialList>
          </FooterRight>{' '}
        </div>
      </Community>
    </Container>
  )
}

const Community = styled.div`
  padding: 64px 0 120px 0;

  background-color: #0d0c18;
  background-size: cover;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 991px) {
    .container {
      flex-direction: column;
      justify-content: center;
    }
  }
  @media screen and (max-width: 700px) {
    padding-bottom: 64px;
  }
`
const CommunityTitle = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 14px;
  margin-bottom: 24px;
  text-align: right;
  @media screen and (max-width: 991px) {
    text-align: center;
    margin-top: 24px;
  }
  @media screen and (max-width: 700px) {
    margin-top: 16px;
  }
`
const SocialList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 700px) {
    gap: 8px;
  }
`
const SocialBox = styled.a`
  transition: 0.3s;
  &:hover {
    transform: translateY(3px);
  }

  font-size: 14px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 144px; */
  .icon-img {
    width: 24px;
  }
  /* @media screen and (max-width: 991px) {
    width: 110px;
    padding: 16px;
  } */
  @media screen and (max-width: 700px) {
    /* width: 62px; */

    padding: 8px;
    font-size: 12px;
    .icon-img {
      width: 16px;
    }
  }
`

const FooterWrapper1 = styled.div`
  width: 100%;
  margin-top: 24px;
  .bottom {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
`

const Socials = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(5, 24px);
  gap: 32px;
  a:hover img {
    transition: 0.3s;
    transform: scale(1.2);
  }
`
const ButtonActions = styled.div`
  display: flex;
  margin-top: 32px;
  @media screen and (max-width: 1024px) {
    padding-bottom: 24px;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  @media screen and (max-width: 700px) {
    margin-top: 24px;
  }
`

const Title = styled.div`
  font-weight: 400;
  font-size: 48px;
  line-height: 140%;
  span {
    font-weight: 900;
  }
  margin-bottom: 8px;
  @media screen and (min-width: 1200px) {
    margin-top: 125px;
  }
  @media screen and (max-width: 991px) {
    font-size: 48px;
  }
  @media screen and (max-width: 700px) {
    font-size: 32px;
    margin-bottom: 14px;
  }
`
const Desc = styled.div`
  font-weight: 400;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 140%;
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
`
const LeftCotent = styled.div`
  width: 648px;
  max-width: 100%;
  margin-right: 12px;
  @media screen and (max-width: 991px) {
    margin-right: 0;
    margin-bottom: 40px;
  }
`
const RightContent = styled.div`
  /* @media screen and (max-height: 840px) {
    img {
      height: 100%;
      object-fit: cover;
    }
  } */

  @media screen and (max-width: 1200px) {
    img {
      width: 70%;
      margin-left: auto;
      display: block;
    }
  }
  @media screen and (max-width: 991px) {
    img {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 700px) {
    img {
      width: 260px;
    }
  }
`
const Wrapper = styled.div`
  color: #fff;
  width: 1368px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-top: 108px;
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    padding-top: 96px;
  }
  @media screen and (max-width: 700px) {
    padding-top: 110px;
    padding-left: 16px;
    padding-right: 16px;
  }
`
const Container = styled.div`
  @media screen and (min-width: 1930px) {
    background-size: contain;
  }
  @media screen and (max-width: 700px) {
    background-position: center;
  }
  .btn-docs,
  .btn-launch {
    box-shadow: none;
    font-size: 14px;

    cursor: pointer;
    transition: 0.3s;
    /* &:hover {
      transform: translateY(3px);
    } */

    button {
      cursor: pointer;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff;
      font-weight: 500;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .btn-launch {
    background: #2e81ff;
    border-radius: 12px;
    height: 40px;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    &:hover {
      opacity: 0.65;
    }
  }
  .btn-docs {
    margin-left: 12px;
    height: 40px;
    width: fit-content;

    &:hover {
      opacity: 0.65;
    }
    button {
      background: transparent;
      border: none;
      display: flex;

      justify-content: center;
      align-items: center;
      padding: 0;
    }

    /* White/10 */

    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    svg {
      margin-left: 4px;
    }
  }
  color: #fff;
  .container {
    width: 1368px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`
const TradeBox = styled.div`
  background: #0d0d18;
`
const Stats = styled.div`
  background: #030205;
  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 56px 24px;
    .stats-amount {
      font-weight: 700;
      line-height: 140%;
      font-size: 32px;
      margin-bottom: 12px;
    }
    .stats-label {
      color: rgba(255, 255, 255, 0.6);
      font-size: 16px;

      line-height: 140%;
    }
  }

  @media screen and (max-width: 991px) {
    .container {
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
      > div {
        padding: 24px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .container {
      grid-template-columns: 1fr;
    }
    > div {
      .stats-label {
      }
      .stats-amount {
        font-size: 24px;
      }
    }
  }
`
const TradeBoxImg1 = styled.div`
  height: 339px;
  width: 100%;
  margin-top: -100px;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 991px) {
    height: 302px;
    margin-top: -50px;
  }
  @media screen and (max-width: 375px) {
    height: 156px;
  }
`

const Features = styled.div`
  background: #0d0d18;
  padding: 120px 0;
  .container {
    width: 1144px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px 24px;
  }
  @media screen and (max-width: 768px) {
    padding: 80px 0;
    .container {
      gap: 24px;
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 700px) {
    padding: 60px 0;
  }
`
const FeatureTitle = styled.div`
  font-size: 48px;
  text-align: center;
  font-weight: 900;
  line-height: 140%;
  margin-bottom: 48px;

  @media screen and (max-width: 700px) {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 32px;
  }
`
const ItemFeature = styled.div`
  transition: 0.3s;
  &:hover {
    transform: translateY(3px);
  }
  display: flex;
  align-items: center;

  .feature-item-box {
    max-width: 338px;
    margin-left: 24px;
  }
  .feature-title {
    cursor: pointer;
    font-weight: 700;
    line-height: 140%;
    font-size: 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
  .feature-desc {
    font-size: 16px;
    line-height: 140%;
  }
  @media screen and (max-width: 768px) {
    .feature-item-box {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 700px) {
    > img {
      width: 80px;
    }
    .feature-item-box {
      margin-left: 16px;
    }
    .feature-title {
      font-size: 16px;
    }
    .feature-desc {
      font-size: 14px;
    }
  }
`

const TradeBoxContent = styled.div`
  padding-top: 75px;
  display: flex;
  align-items: center;
  .left-content {
    margin-right: 24px;
    img {
      margin-left: 59px;
      margin-right: 59px;
    }
  }
  .right-content {
    .tradebox-title {
      margin-top: -36px;
      div {
        line-height: 140%;
        font-size: 48px;
        font-weight: 900;
      }
      margin-bottom: 24px;
    }
    .tradebox-desc {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 24px;
    }
    .tradebox-actions {
      display: flex;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: 67px;
    .left-content {
      margin-right: 24px;
      img {
        margin-left: 20px;
        margin-right: 20px;
        max-width: 308px;
      }
    }
    .right-content {
      .tradebox-title {
        margin-top: -32px;
        div {
          font-size: 32px;
          font-weight: 700;
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    .left-content {
      margin-left: auto;
      margin-right: auto;
      margin-top: 12px;
      img {
        max-width: 226px;
      }
    }
    .right-content {
      .tradebox-title {
        margin-top: -8px;
      }
    }
    align-items: flex-start;
    flex-direction: column-reverse;
  }
`
const Partner = styled.div`
  padding: 120px 0;
  background: #030205;
  .container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 48px 24px;
    > a {
      transition: 0.3s;
      &:hover {
        transform: translateY(3px);
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 60px 0;
    .container {
      gap: 32px 24px;
    }
  }
  @media screen and (max-width: 700px) {
    padding: 40px 0;
    .container {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      > a {
        text-align: center;
      }
    }
  }
`
// const PartnerTitle = styled.div`
//   font-size: 48px;
//   text-align: center;
//   font-weight: 900;
//   line-height: 140%;
//   margin-bottom: 40px;

//   @media screen and (max-width: 700px) {
//     font-weight: 700;
//     font-size: 32px;
//   }
// `

const FooterLeft = styled.div`
  @media screen and (max-width: 991px) {
    text-align: center;
  }
`
const FooterRight = styled.div``

export default Home
